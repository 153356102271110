import { api } from '@/shared/api';
import type {
  GetLogisticPointsParams,
  LogisticPoint,
} from '@/shared/api/generated-api/logistic-points/data-contracts.ts';
import { generalErrorHandler } from '@/shared/lib/utils';
import type { ResponseWithPagination } from '@/shared/types';

export const getLogisticPointById = async (logisticPointId: string): Promise<LogisticPoint> => {
  try {
    const { data } = await api.logisticPoints.getLogisticPointById(logisticPointId);

    return data;
  } catch (error: any) {
    generalErrorHandler(error);
    throw error;
  }
};

export const getLogisticPoints = async (
  filter: GetLogisticPointsParams = {},
): Promise<ResponseWithPagination<LogisticPoint>> => {
  try {
    console.log('mock');
    const { data } = await api.logisticPoints.getLogisticPoints(filter);
    return data;
  } catch (error: any) {
    generalErrorHandler(error);
    throw error;
  }
};

export const getLogisticPointByShortName = async (logisticPointShortName: string): Promise<LogisticPoint> => {
  try {
    const { data } = await api.logisticPoints.getLogisticPointByShortName(logisticPointShortName);

    return data;
  } catch (error: any) {
    generalErrorHandler(error);
    throw error;
  }
};
