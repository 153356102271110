import type { ApiMode, SlideItem } from '@mm-frontend/ops-auth';

import { isDevelopmentApiUrl } from '@/shared/env';

export const slides: SlideItem[] = [
  {
    image: 'img/slider/slide1',
    title: '',
    date: '',
  },
];

export const currentMode: ApiMode = isDevelopmentApiUrl ? 'dev' : 'prod';
