/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  GenericBadRequestError,
  GenericError,
  GetTripsParams,
  HttpMethod,
  Pagination,
  Trip,
  TripEvent,
} from './data-contracts';
import { HttpClient, RequestParams } from './http-client';

export const TripsRoutesRegExp = {
  getTrips: {
    regExp: '^\\/api\\/v1\\/trips$',
    method: HttpMethod.GET,
  },
  getTrip: {
    regExp: '^\\/api\\/v1\\/trips\\/\\d+$',
    method: HttpMethod.GET,
  },
  updateTrip: {
    regExp: '^\\/api\\/v1\\/trips\\/\\d+$',
    method: HttpMethod.PUT,
  },
  cancelTrip: {
    regExp: '^\\/api\\/v1\\/trips\\/\\d+\\/status\\/canceled$',
    method: HttpMethod.PATCH,
  },
  updateTripEvent: {
    regExp: '^\\/api\\/v1\\/trips\\/\\d+$',
    method: HttpMethod.PUT,
  },
};

export class Trips<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * @description Получить список рейсов
   *
   * @tags Trips, Admin
   * @name GetTrips
   * @summary Список рейсов
   * @request GET:/api/v1/trips
   */
  getTrips = (query: GetTripsParams, params: RequestParams = {}) =>
    this.request<
      {
        list: Trip[];
        /** Постраничная навигация */
        pagination: Pagination;
      },
      GenericBadRequestError | GenericError
    >({
      path: `/api/v1/trips`,
      method: 'GET',
      query: query,
      ...params,
    });
  /**
   * @description Получить рейс
   *
   * @tags Trips, Admin
   * @name GetTrip
   * @summary Рейс
   * @request GET:/api/v1/trips/{trip_number}
   */
  getTrip = (tripNumber: number, params: RequestParams = {}) =>
    this.request<Trip, GenericBadRequestError | GenericError>({
      path: `/api/v1/trips/${tripNumber}`,
      method: 'GET',
      ...params,
    });
  /**
   * @description Редактировать рейс
   *
   * @tags Trips, Admin
   * @name UpdateTrip
   * @summary Редактирование рейса
   * @request PUT:/api/v1/trips/{trip_number}
   */
  updateTrip = (tripNumber: number, data: Trip, params: RequestParams = {}) =>
    this.request<Trip, GenericBadRequestError | GenericError>({
      path: `/api/v1/trips/${tripNumber}`,
      method: 'PUT',
      body: data,
      ...params,
    });
  /**
   * @description Обновление статуса рейса на CANCELED
   *
   * @tags Trips, Admin
   * @name CancelTrip
   * @summary Обновление статуса рейса на CANCELED
   * @request PATCH:/api/v1/trips/{trip_number}/status/canceled
   */
  cancelTrip = (tripNumber: number, data: Trip, params: RequestParams = {}) =>
    this.request<Trip, GenericBadRequestError | GenericError>({
      path: `/api/v1/trips/${tripNumber}/status/canceled`,
      method: 'PATCH',
      body: data,
      ...params,
    });
  /**
   * @description Обновить событие рейса
   *
   * @tags Trips, Admin
   * @name UpdateTripEvent
   * @summary Обновление события рейса
   * @request PUT:/api/v1/trips/{trip_number}/events/{trip_event_id}
   */
  updateTripEvent = (tripNumber: number, tripEventId: string, data: TripEvent, params: RequestParams = {}) =>
    this.request<TripEvent, GenericBadRequestError | GenericError>({
      path: `/api/v1/trips/${tripNumber}/events/${tripEventId}`,
      method: 'PUT',
      body: data,
      ...params,
    });
}
