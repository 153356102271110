import type { MOptionItem, MStatusDotColor } from '@mm-frontend/mithril-ui-kit';

import {
  ContractType,
  OwnershipType,
  RegistrationCountry,
  ServiceType,
  VehicleStatus,
} from '@/shared/api/generated-api/fleets/data-contracts.ts';
import { readableEnum } from '@/shared/lib/utils';

export const MIN_SEARCH_LENGTH = 3;
export const VEHICLE_SELECT_PER_PAGE_SIZE = 10;

export const statusDotColorMap: Record<VehicleStatus, MStatusDotColor> = {
  [VehicleStatus.VEHICLE_STATUS_ACTIVE]: 'green',
  [VehicleStatus.VEHICLE_STATUS_ARCHIVE]: 'gray',
  [VehicleStatus.VEHICLE_STATUS_REPAIR]: 'orange',
  [VehicleStatus.VEHICLE_STATUS_DRAFT]: 'persian',
};

export const readableVehicleStatus = readableEnum<VehicleStatus>({
  [VehicleStatus.VEHICLE_STATUS_ACTIVE]: 'Активен',
  [VehicleStatus.VEHICLE_STATUS_REPAIR]: 'В гараже',
  [VehicleStatus.VEHICLE_STATUS_ARCHIVE]: 'Архив',
  [VehicleStatus.VEHICLE_STATUS_DRAFT]: 'Черновик',
});

export const readableOwnershipType = readableEnum<OwnershipType>({
  [OwnershipType.OWNERSHIP_TYPE_OWNERSHIP]: 'Собственность',
  [OwnershipType.OWNERSHIP_TYPE_JOINT_OWNERSHIP_OF_SPOUSES]: 'Совместная собственность супругов',
  [OwnershipType.OWNERSHIP_TYPE_IN_LEASING]: 'В лизинге',
  [OwnershipType.OWNERSHIP_TYPE_RENTED]: 'В аренде',
  [OwnershipType.OWNERSHIP_TYPE_GRATUITOUS_POSSESSION]: 'Безвозмездное пользование',
});

export const readableServiceType = readableEnum<ServiceType>({
  [ServiceType.SERVICE_TYPE_CONTRACT]: 'По договору',
  [ServiceType.SERVICE_TYPE_SELF_SERVICE]: 'Самостоятельное обсуживание',
  [ServiceType.SERVICE_TYPE_WARRANTY]: 'По гарантии',
});

export const readableRegistrationCountry = readableEnum<RegistrationCountry>({
  [RegistrationCountry.REGISTRATION_COUNTRY_RUSSIA]: 'Россия',
});

export const getIsRequiredContract = (ownershipType?: OwnershipType): boolean => {
  return (
    !!ownershipType &&
    [
      OwnershipType.OWNERSHIP_TYPE_IN_LEASING,
      OwnershipType.OWNERSHIP_TYPE_GRATUITOUS_POSSESSION,
      OwnershipType.OWNERSHIP_TYPE_RENTED,
    ].includes(ownershipType)
  );
};

export const contractTypeByOwnershipType = readableEnum<OwnershipType, ContractType, true>({
  [OwnershipType.OWNERSHIP_TYPE_RENTED]: ContractType.CONTRACT_TYPE_RENTAL,
  [OwnershipType.OWNERSHIP_TYPE_IN_LEASING]: ContractType.CONTRACT_TYPE_LEASING,
  [OwnershipType.OWNERSHIP_TYPE_GRATUITOUS_POSSESSION]: ContractType.CONTRACT_TYPE_GRATUITOUS_USE,
});

export const cargoVolumeOptions: MOptionItem[] = [
  {
    id: 2.5,
    title: '2.5',
  },
  {
    id: 3,
    title: '3',
  },
  {
    id: 9,
    title: '9',
  },
  {
    id: 12,
    title: '12',
  },
  {
    id: 13,
    title: '13',
  },
  {
    id: 18,
    title: '18',
  },
  {
    id: 20,
    title: '20',
  },
  {
    id: 40,
    title: '40',
  },
  {
    id: 48,
    title: '48',
  },
  {
    id: 80,
    title: '80',
  },
];

export { createValidationRules as createPassportFormValidationRules } from './passport-form';
export { validationRules as registrationFormValidationRules } from './registration-form';
export { validationRules as dataFormValidationRules } from './data-form';
export { createValidationRules as createServiceFormValidationRules } from './service-form';
export { createValidationRules as createOwnershipFormValidationRules } from './ownership-form';
export { validationRules as linkModelValidationRules } from './link-model-form';
