import type { MTooltipOptions } from '@mm-frontend/mithril-ui-kit';
import { computed, type ComputedRef } from 'vue';
import type { NavigationGuardWithThis } from 'vue-router';

import { RouteName } from '@/shared/config/router';
import { AccountRole, type AccountPermissions, type TmsUserInfo } from '@/shared/types';

const ADMIN_ROLES = new Set([AccountRole.ADMIN, AccountRole.TMS_ADMIN]);

/**
 * Проверяет доступ пользователя на основе предоставленных ролей и разрешений.
 *
 * @param  user - Пользователь, для которого необходимо проверить доступ.
 * @param roles - Роли для сравнения с ролью пользователя.
 * @param permissions - Разрешения для сравнения с разрешениями пользователя.
 * @return {boolean} Возвращает true, если у пользователя есть доступ на основе ролей или разрешений, в противном случае false.
 */
export const checkAccess = ({
  user,
  roles = [],
  permissions = [],
}: {
  user: TmsUserInfo;
  roles?: AccountRole[];
  permissions?: AccountPermissions[];
}): boolean =>
  ADMIN_ROLES.has(user.role) ||
  permissions.some((permission) => user.permissions.includes(permission)) ||
  roles.includes(user.role as AccountRole);

export const createBeforeEnterRouteAccessCheck =
  (permissionCheck: ComputedRef<boolean>): NavigationGuardWithThis<undefined> | NavigationGuardWithThis<undefined>[] =>
  (_, __, next) => {
    if (permissionCheck.value) {
      next();
    } else {
      next({ name: RouteName.NOT_FOUND });
    }
  };

export const createNoAccessTooltip = (isDisabled: ComputedRef<boolean>): ComputedRef<MTooltipOptions> =>
  computed(() => ({
    content: 'Недостаточно прав',
    disabled: isDisabled.value,
  }));
