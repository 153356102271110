/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/** Адрес */
export interface Address {
  /**
   * ID адреса
   * @format uuid
   * @example b32baf8b-f939-4e27-9bab-f13223399eed
   */
  id?: string;
  /**
   * Полный адрес логистического пункта
   * @example Россия, Москва, ул. Тверская, 7
   */
  fullAddress?: string;
  /**
   * Страна
   * @example Россия
   */
  country: string;
  /**
   * регион
   * @example Московская область
   */
  region: string;
  /**
   * код региона
   * @example 1
   */
  regionCode: number;
  /**
   * Почтовый индекс
   * @example 123456
   */
  postalCode: string;
  /**
   * район
   * @example Тверской район
   */
  district?: string;
  /**
   * населенный пункт
   * @example Москва
   */
  city: string;
  /**
   * улица
   * @example ул. Тверская
   */
  street: string;
  /**
   * дом
   * @example 5
   */
  house: string;
  /**
   * корпус, строение
   * @example 1
   */
  building?: string;
  /**
   * квартира
   * @example 1
   */
  apartment?: string;
  /**
   * широта
   * @format double
   * @example 55.75
   */
  latitude: number;
  /**
   * долгота
   * @format double
   * @example 43.25
   */
  longitude: number;
  /**
   * таймзона
   * @example Europe/Moscow
   */
  timezone: string;
}

/**
 * Статус логистического пункта
 * @example "LOGISTIC_POINT_STATUS_OPEN"
 */
export enum LogisticPointStatus {
  LOGISTIC_POINT_STATUS_CLOSED = 'LOGISTIC_POINT_STATUS_CLOSED',
  LOGISTIC_POINT_STATUS_OPEN = 'LOGISTIC_POINT_STATUS_OPEN',
}

/**
 * Тип логистического пункта
 * @example "LOGISTIC_POINT_TYPE_PICKUP_POINT"
 */
export enum LogisticPointType {
  LOGISTIC_POINT_TYPE_CROSSDOCK = 'LOGISTIC_POINT_TYPE_CROSSDOCK',
  LOGISTIC_POINT_TYPE_DISTRIBUTION_CENTER = 'LOGISTIC_POINT_TYPE_DISTRIBUTION_CENTER',
  LOGISTIC_POINT_TYPE_DROPOFF_POINT = 'LOGISTIC_POINT_TYPE_DROPOFF_POINT',
  LOGISTIC_POINT_TYPE_FULLFILLMENT = 'LOGISTIC_POINT_TYPE_FULLFILLMENT',
  LOGISTIC_POINT_TYPE_PICKUP_POINT = 'LOGISTIC_POINT_TYPE_PICKUP_POINT',
  LOGISTIC_POINT_TYPE_SELLER = 'LOGISTIC_POINT_TYPE_SELLER',
  LOGISTIC_POINT_TYPE_SORTING_CENTER = 'LOGISTIC_POINT_TYPE_SORTING_CENTER',
}

/** Город */
export interface City {
  /**
   * Название населенного пункта
   * @example Москва
   */
  name: string;
}

/** Логистический пункт */
export interface LogisticPoint {
  /**
   * ID логистического пункта
   * @format uuid
   * @example b32baf8b-f939-4e27-9bab-f13223399eed
   */
  id?: string;
  /**
   * ID логистического пункта в старом формате
   * @format int64
   * @example 1234567890
   */
  oldId?: number;
  /** Юридическое лицо */
  legalEntity: LegalEntity;
  /** Адрес */
  address: Address;
  schedule: Schedule[];
  /**
   * Название логистического пункта
   * @example b32baf8b-f939-4e27-9bab-f13223399eed
   */
  name?: string;
  /**
   * Сокращенное название логистического пункта
   * @example КЗН-1
   */
  shortName: string;
  /** Тип логистического пункта */
  type: LogisticPointType;
  /** Статус логистического пункта */
  status: LogisticPointStatus;
  /** Тип логистического пункта */
  ownershipType: OwnershipType;
}

/** Расписание работы логистического пункта */
export interface Schedule {
  /**
   * ID расписания
   * @format uuid
   * @example b32baf8b-f939-4e27-9bab-f13223399eed
   */
  id?: string;
  /** День недели */
  dayOfWeek: DayOfWeek;
  /**
   * Время открытия в формате hh:mm
   * @example 07:00
   */
  openedAt: string;
  /**
   * Время закрытия в формате hh:mm
   * @example 07:00
   */
  closedAt: string;
  /**
   * Время начала перерыва в формате hh:mm
   * @example 07:00
   */
  breakStartAt?: string;
  /**
   * Время окончания перерыва в формате hh:mm
   * @example 07:00
   */
  breakEndAt?: string;
}

/**
 * День недели
 * @example "DAY_OF_WEEK_MONDAY"
 */
export enum DayOfWeek {
  DAY_OF_WEEK_FRIDAY = 'DAY_OF_WEEK_FRIDAY',
  DAY_OF_WEEK_MONDAY = 'DAY_OF_WEEK_MONDAY',
  DAY_OF_WEEK_SATURDAY = 'DAY_OF_WEEK_SATURDAY',
  DAY_OF_WEEK_SUNDAY = 'DAY_OF_WEEK_SUNDAY',
  DAY_OF_WEEK_THURSDAY = 'DAY_OF_WEEK_THURSDAY',
  DAY_OF_WEEK_TUESDAY = 'DAY_OF_WEEK_TUESDAY',
  DAY_OF_WEEK_WEDNESDAY = 'DAY_OF_WEEK_WEDNESDAY',
}

/** Юридическое лицо */
export interface LegalEntity {
  /**
   * ID юр. лица
   * @format uuid
   * @example b32baf8b-f939-4e27-9bab-f13223399eed
   */
  id?: string;
  /** Тип юр. лица */
  legalType: LegalEntityType;
  /**
   * Название юр лица
   * @example ООО Ромашка
   */
  name: string;
  /**
   * ИНН
   * @example 1234567890
   */
  inn: string;
  /**
   * КПП
   * @example 1234567890
   */
  kpp: string;
  /**
   * ОГРН для ЮЛ и ОГРНИП для ИП
   * @example 1234567890
   */
  ogrn: string;
  /**
   * Юридический адрес
   * @example Москва, ул. Ленина, д. 1
   */
  address: string;
  /**
   * Телефон
   * @example +7 (495) 123-45-67
   */
  phone: string;
}

/**
 * Тип логистического пункта
 * @example "OWNERSHIP_TYPE_MM"
 */
export enum OwnershipType {
  OWNER_SHIP_TYPE_BUSINESS_PARTNER = 'OWNER_SHIP_TYPE_BUSINESS_PARTNER',
  OWNERSHIP_TYPE_MM = 'OWNERSHIP_TYPE_MM',
  OWNERSHIP_TYPE_PARTNER = 'OWNERSHIP_TYPE_PARTNER',
}

/**
 * Тип юр. лица
 * @example "LEGAL_TYPE_INDIVIDUAL"
 */
export enum LegalEntityType {
  LEGAL_TYPE_INDIVIDUAL = 'LEGAL_TYPE_INDIVIDUAL',
  LEGAL_TYPE_LEGAL = 'LEGAL_TYPE_LEGAL',
}

/** Страница */
export interface Page {
  /**
   * Количество элементов на странице
   * @min 0
   * @max 500
   * @example 10
   */
  size: number;
  /**
   * Номер страницы
   * @min 1
   * @example 4
   */
  number: number;
}

/** Постраничная навигация */
export interface Pagination {
  /** Страница */
  self: Page;
  /** Страница */
  first: Page;
  /** Страница */
  prev?: Page;
  /** Страница */
  next?: Page;
  /** Страница */
  last: Page;
  /**
   * Общее количество элементов
   * @min 0
   * @example 100
   */
  size: number;
}

/** Error response. */
export interface GenericError {
  /** Код ошибки */
  code: ErrorCode;
  /**
   * Заголовок
   * @example Ошибка списания
   */
  title: string;
  /**
   * Подробное описание ошибки
   * @example Error message
   */
  detail: string;
  /**
   * Текст ошибки для команды разработки
   * @example Error message
   */
  debugMessage: string;
}

/** Возврат ошибки запроса */
export interface GenericBadRequestError {
  /** Код ошибки */
  code: ErrorCode;
  /**
   * Заголовок
   * @example Ошибка создания автомобиля
   */
  title: string;
  /**
   * Подробное описание ошибки
   * @example При попытке создать автомобиль, обнаружены ошибки. Попробуйте исправить их и попробовать снова.
   */
  detail: string;
  /**
   * Текст ошибки для команды разработки
   * @example Field: vehicle.model, message: не указана модель автомобиля
   */
  debugMessage: string;
  /** Список ошибок валидации */
  validationErrors: ValidationErrors;
}

/** Код ошибки */
export enum ErrorCode {
  Value400100 = 400100,
  Value403100 = 403100,
  Value404100 = 404100,
  Value409100 = 409100,
  Value422100 = 422100,
  Value500100 = 500100,
}

/** Список ошибок валидации */
export type ValidationErrors = ValidationError[];

/** Ошибка валидации */
export interface ValidationError {
  /**
   * Наименование поля
   * @example ID
   */
  fieldName: string;
  /**
   * Сообщение с ошибкой валидации
   * @example Укажите ID в формате UUID
   */
  message: string;
}

export interface GetLogisticPointsParams {
  /**
   * Город логистического пункта
   * @example Москва
   */
  city?: string;
  /**
   * Код логистического пункта
   * @example КЭН-12
   */
  code?: string;
  /** Тип логистического пункта */
  type?: LogisticPointType[];
  /** Статус логистического пункта */
  status?: LogisticPointStatus[];
  /** Страница */
  page?: Page;
}

export enum HttpMethod {
  GET = 'get',
  POST = 'post',
  PUT = 'put',
  PATCH = 'patch',
  DELETE = 'delete',
}
