import type { App } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';

import { routes } from '@/app/router';
import type { RouteName } from '@/shared/config/router';
import { getReadableRouteName } from '@/shared/config/router';
import { useRouteLoadingStore } from '@/shared/config/stores';

export const withRouter = (app: App): void => {
  const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(_, __, savedPosition) {
      return savedPosition || { top: 0 };
    },
  });

  router.afterEach((to) => {
    const readableRouteName = to.name ? getReadableRouteName(to.name as RouteName) : '';

    if (!to.meta.title) {
      document.title = readableRouteName;

      return;
    }

    document.title = to.meta.title({ readableRouteName, route: to });
  });

  router.beforeResolve(() => {
    useRouteLoadingStore().setLoading(false);
  });

  app.use(router);
};
