<template>
  <m-select
    v-model="activeLogisticPointKey"
    :options="options"
    :size="props.size"
    :input-props="{
      placeholder: props.placeholder,
      label: props.label,
      variant: props.variant,
      showClearableInRest: props.isClearable,
      clearable: props.isClearable,
      errors: props.errors,
    }"
    :is-readonly="props.isReadonly"
    :is-loading="isLoading && searchLengthEnough"
    is-searchable
    is-external-filter
    class="logistic-points-select"
    @update:model-value="onUpdateActiveLogisticPointKey"
    @open="onOpenSelect"
    @close="isOpenSelect = false"
    @update:search="onUpdateSearch"
  >
    <template #misc-item-after>
      <m-typography
        v-if="!options.length && searchLengthEnough"
        variant="TaglineMMedium"
        color="tertiary"
        class="no-data"
        :class="{
          'no-data--loading': isLoading,
        }"
      >
        <template v-if="!isLoading">Нет данных. Измените поиск</template>
      </m-typography>
      <m-typography
        v-if="!searchLengthEnough"
        variant="TaglineMMedium"
        color="tertiary"
        class="no-data"
      >
        Введите от {{ MIN_SEARCH_LENGTH }} символов
      </m-typography>
    </template>
  </m-select>
</template>

<script setup lang="ts">
import { MSelect, MTypography } from '@mm-frontend/mithril-ui-kit';
import type { MCheckedId, MInputSize, MInputVariant, MOptionItem } from '@mm-frontend/mithril-ui-kit';
import { debounce } from 'ts-debounce';
import { computed, ref, watch } from 'vue';

import { getLogisticPoints } from '../api';
import { MIN_SEARCH_LENGTH } from '../config';
import type { OptionItem } from '../types';

const emit = defineEmits<{
  'update:model-value': [value?: OptionItem];
}>();

const props = withDefaults(
  defineProps<{
    modelValue?: OptionItem | null;
    placeholder?: string;
    isReadonly?: boolean;
    label?: string;
    variant?: MInputVariant;
    size?: MInputSize;
    errors?: string[];
    isClearable?: boolean;
  }>(),
  {
    modelValue: undefined,
    placeholder: 'Выберите логистический пункт',
    label: undefined,
    variant: undefined,
    size: undefined,
    errors: () => [],
    isClearable: true,
  },
);

const activeLogisticPointKey = ref<string | undefined>(props.modelValue?.shortName);
const activeLogisticPointsList = ref<OptionItem[]>([]);

const logisticPointsList = ref<OptionItem[]>([]);
const isLoading = ref(false);
const search = ref('');

const isOpenSelect = ref(false);

const convertLogisticPointToOption = (logisticPoint: OptionItem): MOptionItem => ({
  id: logisticPoint.shortName,
  title: logisticPoint.shortName,
});

const searchLengthEnough = computed(() => search.value.length >= MIN_SEARCH_LENGTH);

const options = computed<MOptionItem[]>(() => {
  if (!isOpenSelect.value) return activeLogisticPointsList.value.map(convertLogisticPointToOption);

  return logisticPointsList.value.map(convertLogisticPointToOption);
});

const loadLogisticPoints = async (): Promise<void> => {
  isLoading.value = true;
  console.log(1);

  try {
    console.log(2);
    const response = await getLogisticPoints({
      code: search.value,
      page: {
        number: 1,
        size: 50,
      },
    });
    console.log(3);

    logisticPointsList.value.push(
      ...response.list.sort((a, b) =>
        a.shortName.localeCompare(b.shortName, 'ru-RU', {
          numeric: true,
        }),
      ),
    );
  } finally {
    console.log(4);
    isLoading.value = false;
  }
};

const resetData = (): void => {
  logisticPointsList.value = [];
};

const onUpdateSearch = debounce((newSearch: string) => {
  resetData();
  search.value = newSearch;
  console.log(1);
  if (isOpenSelect.value && searchLengthEnough.value) {
    loadLogisticPoints();
  }
}, 300);

const onOpenSelect = (): void => {
  isOpenSelect.value = true;
};

const onUpdateActiveLogisticPointKey = (logisticPointKey?: MCheckedId | MCheckedId[]): void => {
  const logisticPointItem = logisticPointsList.value.find((item) => item.shortName === logisticPointKey);

  emit('update:model-value', logisticPointItem);

  resetData();

  if (!logisticPointItem) return;

  activeLogisticPointsList.value.push(logisticPointItem);
};

watch(
  () => props.modelValue,
  (logisticPoint) => {
    if (logisticPoint) {
      activeLogisticPointKey.value = logisticPoint.shortName;
      activeLogisticPointsList.value.push(logisticPoint);
    } else {
      activeLogisticPointKey.value = undefined;
      activeLogisticPointsList.value = [];
    }
  },
  {
    immediate: true,
  },
);
</script>

<style lang="scss" scoped>
.logistic-points-select {
  .no-data {
    padding: 8px;

    &--loading {
      padding: 15px;
    }
  }
}
</style>
