import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { useRouter } from 'vue-router';

import { RouteName } from '@/shared/config/router';
import { useAuthStore, useCacheStore, useUserStore } from '@/shared/config/stores';
import { checkAccess } from '@/shared/lib/utils';
import { AccountPermissions, type TmsUserWithTokens } from '@/shared/types';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useSession = () => {
  const authStore = useAuthStore();
  const userStore = useUserStore();
  const router = useRouter();
  const cacheStore = useCacheStore();

  /**
   * Сохраняет данные пользователя в authStore и userStore, и редиректит на главную\предыщущую страницу, сохраненную в сторе
   *
   * @param {TmsUserWithTokens} user - Никнейм пользователя
   */

  const loginUser = async (user: TmsUserWithTokens): Promise<void> => {
    authStore.setTokens(user.accessToken, user.refreshToken);
    userStore.setUserInfo(user.userInfo);

    const nextRoute = cacheStore.nextRoute;
    cacheStore.nextRoute = null;

    router.push({
      name: nextRoute?.name ?? RouteName.HOME,
      params: nextRoute?.params,
      query: nextRoute?.query,
    });
  };

  const goToRestore = (): void => {
    router.push({
      name: RouteName.RESTORE,
    });
  };

  const goToLogin = (): void => {
    router.push({
      name: RouteName.LOGIN,
    });
  };

  return {
    loginUser,
    goToRestore,
    goToLogin,
  };
};

// Чтобы сразу при импорте не было создания стора, поскольку тогда получится,
// что будет ошибка использования Пиньи до инициализации
// (импорт в роутах, потом импорт роутов в импорте плагина роута вместе с плагином Пиньи и в итоге до инициализации)
const user = computed(() => {
  const userStore = useUserStore();
  const { userInfo } = storeToRefs(userStore);
  return userInfo.value;
});

const checkAccessUser = (permissions: AccountPermissions[]): boolean => {
  return checkAccess({
    user: user.value,
    permissions,
  });
};

const canReadCarrier = computed(() => checkAccessUser([AccountPermissions.CARRIERS_GET_CARRIER]));
const canReadCarriers = computed(() => checkAccessUser([AccountPermissions.CARRIERS_GET_CARRIERS]));
const canReadDrivers = computed(() => checkAccessUser([AccountPermissions.FMS_DRIVERS_GET_DRIVERS]));
const canReadDriver = computed(() => checkAccessUser([AccountPermissions.FMS_DRIVERS_GET_DRIVER]));
const canCreateStaffDriver = computed(() => checkAccessUser([AccountPermissions.FMS_DRIVERS_CREATE_STAFF_DRIVER]));
const canUpdateStaffDriver = computed(() => checkAccessUser([AccountPermissions.FMS_DRIVERS_UPDATE_STAFF_DRIVER]));
const canCreateOutsourceDriver = computed(() =>
  checkAccessUser([AccountPermissions.FMS_DRIVERS_CREATE_OUTSOURCE_DRIVER]),
);
const canUpdateOutsourceDriver = computed(() =>
  checkAccessUser([AccountPermissions.FMS_DRIVERS_UPDATE_OUTSOURCE_DRIVER]),
);
const canCreateDriverLicense = computed(() => checkAccessUser([AccountPermissions.FMS_DRIVERS_CREATE_DRIVER_LICENSE]));
const canReadDriverLicense = computed(() => checkAccessUser([AccountPermissions.FMS_DRIVERS_GET_DRIVER_LICENSES]));
const canDeleteDriverLicense = computed(() => checkAccessUser([AccountPermissions.FMS_DRIVERS_DELETE_DRIVER_LICENSE]));
const canReadLogisticPoints = computed(() => checkAccessUser([AccountPermissions.LOGISTIC_POINTS_GET_LOGISTIC_POINTS]));
const canReadLogisticPoint = computed(() => checkAccessUser([AccountPermissions.LOGISTIC_POINTS_GET_LOGISTIC_POINT]));
const canReadModels = computed(() => checkAccessUser([AccountPermissions.FMS_FLEETS_GET_MODELS]));
const canReadModel = computed(() => checkAccessUser([AccountPermissions.FMS_FLEETS_GET_MODEL]));
const canUpdateModel = computed(() => checkAccessUser([AccountPermissions.FMS_FLEETS_UPDATE_MODEL]));
const canCreateModel = computed(() => checkAccessUser([AccountPermissions.FMS_FLEETS_CREATE_MODEL]));
const canReadTransportationOrders = computed(() =>
  checkAccessUser([AccountPermissions.TRANSPORTATION_ORDERS_GET_TRANSPORTATION_ORDERS]),
);
const canReadTransportationOrder = computed(() =>
  checkAccessUser([AccountPermissions.TRANSPORTATION_ORDERS_GET_TRANSPORTATION_ORDER]),
);
const canCreateTransportationOrder = computed(() =>
  checkAccessUser([AccountPermissions.TRANSPORTATION_ORDERS_POST_TRANSPORTATION_ORDER]),
);
const canUpdateTransportationOrder = computed(() =>
  checkAccessUser([AccountPermissions.TRANSPORTATION_ORDERS_PUT_TRANSPORTATION_ORDER]),
);
const canApproveTransportationOrder = computed(() =>
  checkAccessUser([AccountPermissions.TRANSPORTATION_ORDERS_PATCH_TRANSPORTATION_ORDER_STATUS_APPROVED]),
);
const canCancelTransportationOrder = computed(() =>
  checkAccessUser([AccountPermissions.TRANSPORTATION_ORDERS_PATCH_TRANSPORTATION_ORDER_STATUS_CANCELED]),
);
const canCarrierSelectTransportationOrder = computed(() =>
  checkAccessUser([AccountPermissions.TRANSPORTATION_ORDERS_PATCH_TRANSPORTATION_ORDER_STATUS_CARRIER_SELECTION]),
);
// TODO из-за поломки WMS смотрим на неверное правило
const canDeleteTransportationOrder = computed(() =>
  checkAccessUser([AccountPermissions.TRANSPORTATION_ORDERS_GET_TRANSPORTATION_ORDERS]),
);
const canReadTrips = computed(() => checkAccessUser([AccountPermissions.TRIPS_GET_TRIPS]));
const canReadTrip = computed(() => checkAccessUser([AccountPermissions.TRIPS_GET_TRIP]));
const canUpdateTrip = computed(() => checkAccessUser([AccountPermissions.TRIPS_PUT_TRIP]));
const canUpdateTripEvent = computed(() => checkAccessUser([AccountPermissions.TRIPS_PUT_TRIP_EVENT]));
// TODO из-за поломки WMS смотрим на неверное правило
const canCancelTripEvent = computed(() =>
  checkAccessUser([AccountPermissions.TRANSPORTATION_ORDERS_GET_TRANSPORTATION_ORDERS]),
);
const canReadVehicles = computed(() => checkAccessUser([AccountPermissions.FMS_FLEETS_GET_VEHICLES]));
const canReadVehicle = computed(() => checkAccessUser([AccountPermissions.FMS_FLEETS_GET_VEHICLE]));
const canCreateVehicle = computed(() => checkAccessUser([AccountPermissions.FMS_FLEETS_CREATE_VEHICLE]));
const canUpdateVehicle = computed(() => checkAccessUser([AccountPermissions.FMS_FLEETS_UPDATE_VEHICLE]));
const canCreateVehicleRegistration = computed(() =>
  checkAccessUser([AccountPermissions.FMS_FLEETS_CREATE_VEHICLE_REGISTRATION]),
);
const canUpdateVehicleRegistration = computed(() =>
  checkAccessUser([AccountPermissions.FMS_FLEETS_UPDATE_VEHICLE_REGISTRATION]),
);
const canCreateVehiclePassport = computed(() =>
  checkAccessUser([AccountPermissions.FMS_FLEETS_CREATE_VEHICLE_PASSPORT]),
);
const canUpdateVehiclePassport = computed(() =>
  checkAccessUser([AccountPermissions.FMS_FLEETS_UPDATE_VEHICLE_PASSPORT]),
);
const canCreateVehicleContract = computed(() => checkAccessUser([AccountPermissions.FMS_FLEETS_CREATE_CONTRACT]));
const canUpdateVehicleContract = computed(() => checkAccessUser([AccountPermissions.FMS_FLEETS_UPDATE_CONTRACT]));
const canMakeVehicleActive = computed(() => checkAccessUser([AccountPermissions.FMS_FLEETS_MAKE_VEHICLE_ACTIVE]));
const canMakeVehicleArchive = computed(() => checkAccessUser([AccountPermissions.FMS_FLEETS_MAKE_VEHICLE_ARCHIVE]));

export {
  canReadCarrier,
  canReadCarriers,
  canReadDrivers,
  canReadDriver,
  canCreateStaffDriver,
  canUpdateStaffDriver,
  canCreateOutsourceDriver,
  canUpdateOutsourceDriver,
  canReadDriverLicense,
  canDeleteDriverLicense,
  canCreateDriverLicense,
  canReadLogisticPoints,
  canReadLogisticPoint,
  canReadModels,
  canReadModel,
  canUpdateModel,
  canCreateModel,
  canReadTransportationOrders,
  canReadTransportationOrder,
  canCreateTransportationOrder,
  canUpdateTransportationOrder,
  canApproveTransportationOrder,
  canCancelTransportationOrder,
  canCarrierSelectTransportationOrder,
  canDeleteTransportationOrder,
  canReadTrips,
  canReadTrip,
  canUpdateTrip,
  canUpdateTripEvent,
  canCancelTripEvent,
  canReadVehicles,
  canReadVehicle,
  canCreateVehicle,
  canUpdateVehicle,
  canCreateVehicleRegistration,
  canUpdateVehicleRegistration,
  canCreateVehiclePassport,
  canUpdateVehiclePassport,
  canCreateVehicleContract,
  canUpdateVehicleContract,
  canMakeVehicleActive,
  canMakeVehicleArchive,
};
