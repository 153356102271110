/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, HeadersDefaults, ResponseType } from 'axios';
import cloneDeep from 'lodash/cloneDeep';
import { isProxy, toRaw } from 'vue';

function cloneQueryObject(query: any) {
  if (Array.isArray(query)) {
    return isProxy(query) ? toRaw(query) : structuredClone(query);
  } else {
    const changedObj = <Record<string, any>>{};
    Object.keys(query).forEach((item: string) => {
      const queryItem = query[item];
      changedObj[item] = isProxy(queryItem) ? toRaw(queryItem) : queryItem;
    });
    return changedObj;
  }
}

function changePropertiesToSnakeCase(query: any) {
  const camelToSnakeCase = (str: string) => str.replace(/[A-Z]/g, (letter: string) => `_${letter.toLowerCase()}`);

  function checkAllProps(query: any) {
    if (query instanceof Object && !(query instanceof File)) {
      for (const prop in query) {
        if (query[prop] instanceof Object) {
          checkAllProps(query[prop]);
        }
        query[camelToSnakeCase(prop)] = query[prop];
        if (camelToSnakeCase(prop) !== prop) {
          delete query[prop];
        }
      }
    }
  }

  if (query instanceof Object) {
    const changedObj = cloneQueryObject(query);
    checkAllProps(changedObj);
    return changedObj;
  } else {
    return query;
  }
}

export function changePropertiesToCamelCase(query: any) {
  if (query instanceof Blob) return query;
  const snakeToCamel = (str: string) =>
    str.replace(/([-_][a-z])/g, (group: string) => group.toUpperCase().replace('-', '').replace('_', ''));
  function checkAllProps(query: any) {
    if (query instanceof Object) {
      for (const prop in query) {
        if (query[prop] instanceof Object) {
          checkAllProps(query[prop]);
        }
        query[snakeToCamel(prop)] = query[prop];
        if (snakeToCamel(prop) !== prop) {
          delete query[prop];
        }
      }
    }
  }
  if (query instanceof Object) {
    const changedObj = cloneQueryObject(query);
    checkAllProps(changedObj);
    return changedObj;
  } else {
    return query;
  }
}

function handleError(error: any): never {
  throw error.response?.data
    ? {
        ...error,
        response: {
          ...error.response,
          data: changePropertiesToCamelCase(error.response.data),
        },
      }
    : error;
}

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>;

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
  securityWorker?: (
    securityData: SecurityDataType | null,
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({
      ...axiosConfig,
      baseURL: axiosConfig.baseURL || 'https://benatar-admin-api-gateway.k8s.dev.market.corp/logistic-points',
    });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method);

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method && this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === 'object' && formItem !== null) {
      return JSON.stringify(formItem);
    } else {
      return `${formItem}`;
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      const propertyContent: any[] = property instanceof Array ? property : [property];

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File;
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
      }

      return formData;
    }, new FormData());
  }

  protected queryPrepare(query: Record<string, any>): Record<string, any> {
    const newQuery: Record<string, any> = {};

    Object.keys(query).forEach((key) => {
      newQuery[key] = Array.isArray(query[key]) && !!query[key].length ? query[key].join(',') : query[key];
    });

    return newQuery;
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    body = changePropertiesToSnakeCase(cloneDeep(body));
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = format || this.format || undefined;

    if (type === ContentType.FormData && body && typeof body === 'object') {
      body = this.createFormData(body as Record<string, unknown>);
    }

    if (type === ContentType.Text && body && typeof body !== 'string') {
      body = JSON.stringify(body);
    }

    return this.instance
      .request({
        ...requestParams,
        headers: {
          ...(requestParams.headers || {}),
          ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
        },
        params: query ? this.queryPrepare(changePropertiesToSnakeCase(query)) : query,
        responseType: responseFormat,
        data: body,
        url: path,
      })
      .then((response) => {
        const data = changePropertiesToCamelCase(response.data);
        return { ...response, data };
      })
      .catch(handleError);
  };
}
