import type { UserWithTokens } from '@mm-frontend/ops-auth';

export enum AccountRole {
  ADMIN = 'admin',
  TMS_ADMIN = 'tms_admin',
  TMS_COORDINATOR = 'tms_coordinator',
  TMS_LOGIST = 'tms_logist',
  TMS_LOGIST_COORDINATOR = 'tms_logist_coordinator',
  TMS_SENIOR_MANAGER_INTRACITY_TRANSPORTATION = 'tms_senior_manager_intracity_transportation',
  TMS_SENIOR_MANAGER_TRUNK_TRANSPORTATION = 'tms_senior_manager_trunk_transportation',
}

export enum AccountPermissions {
  FMS_DRIVERS_GET_DRIVER = 'fms_drivers_get_driver',
  FMS_DRIVERS_UPDATE_STAFF_DRIVER = 'fms_drivers_update_staff_driver',
  FMS_DRIVERS_CREATE_STAFF_DRIVER = 'fms_drivers_create_staff_driver',
  FMS_DRIVERS_UPDATE_OUTSOURCE_DRIVER = 'fms_drivers_update_outsource_driver',
  FMS_DRIVERS_CREATE_OUTSOURCE_DRIVER = 'fms_drivers_create_outsource_driver',
  FMS_DRIVERS_GET_DRIVERS = 'fms_drivers_get_drivers',
  FMS_DRIVERS_GET_DRIVER_LICENSES = 'fms_drivers_get_driver_licenses',
  FMS_DRIVERS_CREATE_DRIVER_LICENSE = 'fms_drivers_create_driver_license',
  FMS_DRIVERS_DELETE_DRIVER_LICENSE = 'fms_drivers_delete_driver_license',
  FMS_FLEETS_GET_VEHICLE = 'fms_fleets_get_vehicle',
  FMS_FLEETS_UPDATE_VEHICLE = 'fms_fleets_update_vehicle',
  FMS_FLEETS_LINK_MODEL_TO_VEHICLE = 'fms_fleets_link_model_to_vehicle',
  FMS_FLEETS_GET_VEHICLES = 'fms_fleets_get_vehicles',
  FMS_FLEETS_CREATE_VEHICLE = 'fms_fleets_create_vehicle',
  FMS_FLEETS_CREATE_OWN_VEHICLE = 'fms_fleets_create_own_vehicle',
  FMS_FLEETS_UPDATE_OWN_VEHICLE = 'fms_fleets_update_own_vehicle',
  FMS_FLEETS_CREATE_OUTSOURCE_VEHICLE = 'fms_fleets_create_outsource_vehicle',
  FMS_FLEETS_UPDATE_OUTSOURCE_VEHICLE = 'fms_fleets_update_outsource_vehicle',
  FMS_FLEETS_CREATE_VEHICLE_PASSPORT = 'fms_fleets_create_vehicle_passport',
  FMS_FLEETS_UPDATE_VEHICLE_PASSPORT = 'fms_fleets_update_vehicle_passport',
  FMS_FLEETS_CREATE_VEHICLE_REGISTRATION = 'fms_fleets_create_vehicle_registration',
  FMS_FLEETS_UPDATE_VEHICLE_REGISTRATION = 'fms_fleets_update_vehicle_registration',
  FMS_FLEETS_MAKE_VEHICLE_ACTIVE = 'fms_fleets_make_vehicle_active',
  FMS_FLEETS_MAKE_VEHICLE_ARCHIVE = 'fms_fleets_make_vehicle_archive',
  FMS_FLEETS_CREATE_CONTRACT = 'fms_fleets_create_contract',
  FMS_FLEETS_UPDATE_CONTRACT = 'fms_fleets_update_contract',
  FMS_FLEETS_GET_MODEL = 'fms_fleets_get_model',
  FMS_FLEETS_UPDATE_MODEL = 'fms_fleets_update_model',
  FMS_FLEETS_GET_MODELS = 'fms_fleets_get_models',
  FMS_FLEETS_CREATE_MODEL = 'fms_fleets_create_model',
  CARRIERS_GET_CARRIERS = 'carriers_get_carriers',
  CARRIERS_GET_CARRIER = 'carriers_get_carrier',
  LOGISTIC_POINTS_GET_LOGISTIC_POINT = 'logistic_points_get_logistic_point',
  LOGISTIC_POINTS_GET_LOGISTIC_POINTS = 'logistic_points_get_logistic_points',
  LOGISTIC_POINTS_GET_CITIES = 'logistic_points_get_cities',
  TRANSPORTATION_ORDERS_GET_TRANSPORTATION_ORDER = 'transportation_orders_get_transportation_order',
  TRANSPORTATION_ORDERS_GET_TRANSPORTATION_ORDERS = 'transportation_orders_get_transportation_orders',
  TRANSPORTATION_ORDERS_POST_TRANSPORTATION_ORDER = 'transportation_orders_post_transportation_order',
  TRANSPORTATION_ORDERS_PUT_TRANSPORTATION_ORDER = 'transportation_orders_put_transportation_order',
  TRANSPORTATION_ORDERS_PATCH_TRANSPORTATION_ORDER_STATUS_APPROVED = 'transportation_orders_patch_transportation_order_status_approved',
  TRANSPORTATION_ORDERS_PATCH_TRANSPORTATION_ORDER_STATUS_CANCELED = 'transportation_orders_patch_transportation_order_status_canceled',
  TRANSPORTATION_ORDERS_PATCH_TRANSPORTATION_ORDER_STATUS_CARRIER_SELECTION = 'transportation_orders_patch_transportation_order_status_carrier_selection',
  TRIPS_GET_TRIPS = 'trips_get_trips',
  TRIPS_GET_TRIP = 'trips_get_trip',
  TRIPS_PUT_TRIP = 'trips_put_trip',
  TRIPS_PUT_TRIP_EVENT = 'trips_put_trip_event',
}

export type TmsUserWithTokens = UserWithTokens<AccountRole, AccountPermissions[]>;
export type TmsUserInfo = TmsUserWithTokens['userInfo'];
