import { computed } from 'vue';
import type { RouteRecordRaw } from 'vue-router';

import { canReadTransportationOrder, canReadTransportationOrders } from '@/entities/session';
import { RouteName } from '@/shared/config/router';
import { useRouteLoadingStore } from '@/shared/config/stores';
import { createBeforeEnterRouteAccessCheck } from '@/shared/lib/utils';

export const route: RouteRecordRaw = {
  path: ':id',
  name: RouteName.TRANSPORTATION_ORDER,
  component: () => {
    useRouteLoadingStore().setLoading(true);
    return import('./ui/transportation-order.vue');
  },
  meta: {
    title({ route, readableRouteName: readableTitle }): string {
      return `${readableTitle} ${route.params.id}`;
    },
  },
  beforeEnter: createBeforeEnterRouteAccessCheck(
    computed(() => {
      return canReadTransportationOrders.value && canReadTransportationOrder.value;
    }),
  ),
};
