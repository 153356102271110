import { computed } from 'vue';
import type { RouteRecordRaw } from 'vue-router';

import {
  canCreateTransportationOrder,
  canReadCarrier,
  canReadCarriers,
  canReadLogisticPoints,
  canReadTransportationOrders,
} from '@/entities/session';
import { RouteName } from '@/shared/config/router';
import { useRouteLoadingStore } from '@/shared/config/stores';
import { createBeforeEnterRouteAccessCheck } from '@/shared/lib/utils';

export const route: RouteRecordRaw = {
  path: 'add',
  name: RouteName.TRANSPORTATION_ORDER_ADD,
  component: () => {
    useRouteLoadingStore().setLoading(true);
    return import('./ui/transportation-order-add.vue');
  },
  beforeEnter: createBeforeEnterRouteAccessCheck(
    computed(() => {
      return (
        canReadTransportationOrders.value &&
        canCreateTransportationOrder.value &&
        canReadLogisticPoints.value &&
        canReadCarriers.value &&
        canReadCarrier.value
      );
    }),
  ),
};
