import type { RouteRecordRaw } from 'vue-router';

import { canReadDrivers } from '@/entities/session';
import type { GetDriversParams } from '@/shared/api/generated-api/drivers/data-contracts.ts';
import { RouteName } from '@/shared/config/router';
import { useRouteLoadingStore } from '@/shared/config/stores';
import { createBeforeEnterRouteAccessCheck } from '@/shared/lib/utils';

export const route: RouteRecordRaw = {
  path: 'drivers',
  name: RouteName.DRIVERS,
  component: () => {
    useRouteLoadingStore().setLoading(true);
    return import('./ui/Drivers.vue');
  },
  beforeEnter: createBeforeEnterRouteAccessCheck(canReadDrivers),
};

export const defaultFilter: GetDriversParams = {
  driverFio: '',
  driverPhone: '',
  driverSnils: '',
  carrierId: undefined,
};

export const DRIVERS_PER_PAGE_SIZE = 50;
