import type { RouteRecordRaw } from 'vue-router';

import { LayoutType, RouteName } from '@/shared/config/router';
import { useRouteLoadingStore } from '@/shared/config/stores';

export const route: RouteRecordRaw = {
  path: '',
  name: RouteName.HOME,
  component: () => {
    useRouteLoadingStore().setLoading(true);
    return import('./ui/home.vue');
  },
  meta: {
    layout: LayoutType.DEFAULT,
  },
};
