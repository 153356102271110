import { defineStore } from 'pinia';

import type { AccountRole, TmsUserInfo } from '@/shared/types';

interface UserStateInfo {
  userInfo: TmsUserInfo;
}

export const useUserStore = defineStore('user', {
  state: (): UserStateInfo => ({
    userInfo: {
      id: 0,
      zoneId: 0,
      firstName: '',
      lastName: '',
      patronymic: '',
      role: '' as AccountRole,
      localizedRole: '',
      permissions: [],
    },
  }),
  actions: {
    setUserInfo(userInfo: TmsUserInfo) {
      this.userInfo = userInfo;
    },
  },
  persist: {
    storage: sessionStorage,
  },
});
