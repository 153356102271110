import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import type { DateWithTimezone } from '@/shared/types';

dayjs.extend(utc);
dayjs.extend(timezone);

/**
 * Конвертирует число секунд в строку в формате "HH:MM".
 *
 * @param {number} seconds - Секунды, которые необходимо преобразовать.
 * @return {string} Время в формате "HH:MM".
 */
export const secondsToTime = (seconds: number): string => {
  return new Date(1000 * seconds).toISOString().slice(11, 16);
};

/**
 * Получает количество секунд и выводит их в читаемом формате (дни, часы, минуты, секунды).
 *
 * @param {number} totalSeconds - Секунды, которые необходимо преобразовать.
 * @param {Object} options - Объект, который содержит опцию показывать секунды (по умолчанию true).
 * @return {string} Время в формате "DDд HHч MMм SSс".
 */
export const secondsToReadableTime = (totalSeconds: number, options?: { showSeconds?: boolean }): string => {
  if (totalSeconds < 0) return '—';
  const days = Math.floor(totalSeconds / 60 / 60 / 24);
  const daysInSeconds = days * 60 * 60 * 24;
  const hours = Math.floor((totalSeconds - daysInSeconds) / 60 / 60);
  const hoursInSeconds = hours * 60 * 60;
  const minutes = Math.floor((totalSeconds - daysInSeconds - hoursInSeconds) / 60);
  const minutesInSeconds = minutes * 60;
  const seconds = totalSeconds - daysInSeconds - hoursInSeconds - minutesInSeconds;

  const showSeconds = options?.showSeconds ?? true;
  return `${days >= 1 ? days + 'д ' : ''}${hours >= 1 ? hours + 'ч ' : ''}${minutes >= 1 ? minutes + 'м ' : ''}${seconds >= 1 && showSeconds ? seconds + 'c' : ''}`;
};

/**
 * Вычисляет время между двумя датами учитывая их таймзоны
 *
 * @param firstDate - Первая дата
 * @param secondDate - Вторая дата
 * @return Разница от первой даты до второй в секундах
 */
export const getTimeDiffWithTimezones = (firstDate: DateWithTimezone, secondDate: DateWithTimezone): number => {
  const firstDateWithTimezone = dayjs.tz(firstDate.date, firstDate.timezone);
  const secondDateWithTimezone = dayjs.tz(secondDate.date, secondDate.timezone);
  return firstDateWithTimezone.diff(secondDateWithTimezone, 'seconds');
};

export const readableTimeToSeconds = (readableTime: string): number => {
  const splitTime = readableTime.split(':');
  const hours = Number.parseInt(splitTime[0]);
  const minutes = Number.parseInt(splitTime[1]);

  return hours * 60 * 60 + minutes * 60;
};

export { default as modifiedDayjs } from 'dayjs';
