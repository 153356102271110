import { helpers, required } from '@vuelidate/validators';

import { REQUIRED_MESSAGE } from '@/shared/config/validation';

export const validationRules: {
  modelId?: object;
} = {
  modelId: {
    required: helpers.withMessage(REQUIRED_MESSAGE, required),
  },
};
