import type { RouteRecordRaw } from 'vue-router';

import { RouteName } from '@/shared/config/router';
import { useRouteLoadingStore } from '@/shared/config/stores';

export const route: RouteRecordRaw = {
  path: 'not-found',
  name: RouteName.NOT_FOUND,
  component: () => {
    useRouteLoadingStore().setLoading(true);
    return import('./ui/NotFound.vue');
  },
};
